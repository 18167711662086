import React, { useState, useEffect } from 'react';

import '../App.css';

const SongMenu = ({
  setShowPasteMenu, 
  exportFile, 
  importFile,
  setShowMenu
}) => {

  const menuItemSelected = (v) => {
    if(v === "paste")
      setShowPasteMenu(true)
    setShowMenu(false)
  }

  return(
    <div 
      style={{
        position: 'absolute', 
        backgroundColor: 'black', 
        height: '80px', 
        width: '110px', 
        top: '60px', 
        right: '0px',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
      }}>
      <div 
        onClick={() => menuItemSelected('paste')}
        className="mainMenuButton"
      >
        Paste
      </div>
      <div 
        onClick={importFile}
        className="mainMenuButton"
      >
        Import
      </div>
      <div 
        onClick={exportFile}
        className="mainMenuButton"
      >
        Export
      </div>
    </div>
  )
}

export default SongMenu;