import React, { useState, useEffect, useRef } from 'react';

const barreCombos = [[1,6],[1,5],[1,4],[2,6],[3,6],[4,5,6]]

function FretBarre({
    tabArray, 
    rootFret, 
    stroke,
    fill,
    ...props
  }) {


  let barre = [0,0]
  let barreNum = -1

  let barreX = 0;
  let barreY = 0;
  let barreWidth = 0;
  let barreHeight = 0;
  
  // for each predefined combos
  for(var i=0; i<barreCombos.length; i++)
  {
    let bCombo0 = barreCombos[i][0] - 1
    let bCombo1 = barreCombos[i][1] - 1
    let bCombo2 = barreCombos[i][2] ? barreCombos[i][2] - 1 : null

    if(
        tabArray[ bCombo0 ] !== '' &&
        tabArray[ bCombo1 ] !== ''
      )
    {
      if( tabArray[ bCombo0 ] === tabArray[ bCombo1 ] )
      {
        const copyArr = [...tabArray].slice(bCombo0, bCombo1)
        let min = Math.min(...copyArr)

        if(min >= tabArray[ bCombo0 ]){
          barre = barreCombos[i]
          barreNum = tabArray[ bCombo0 ]

          barreX = 10 + ( bCombo0 * 24);
          barreWidth = 3 + ((bCombo1 - bCombo0) * 24);

          barreY = 9.5 + (( barreNum - 1 - rootFret ) * 40);
          barreHeight = 23

          break;
        }
        
      }else if(tabArray[ bCombo0 ] === tabArray[ bCombo1 ] === tabArray[ bCombo2 ] )
      {
        const copyArr = [...tabArray].slice(0, bCombo0)
        let max = Math.max(...copyArr)
        if(max < tabArray[ bCombo0 ]){
          barre = barreCombos[i]
          barreNum = tabArray[ bCombo0 ]

          barreX = 10 + ( bCombo0 * 24);
          barreWidth = 3 + ((bCombo2 - bCombo0) * 24);

          barreY = 9.5 + (( barreNum - 1 - rootFret ) * 40);
          barreHeight = 23

          break;
        }
      }
    }
  }

  if(barreNum > 0)
    return( <rect x={barreX} width={barreWidth} height={barreHeight} y={barreY} stroke={stroke} fill={fill} /> )

  return(null)
}

export default FretBarre;
