import React from 'react';

const MenuBurgerSVG = () => {
     return(
               <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="23" width="95" height="5" stroke="black" />
                    <rect x="5" y="48" width="95" height="5" stroke="black" />
                    <rect x="5" y="73" width="95" height="5" stroke="black" />
               </svg>
          )
}

export default MenuBurgerSVG;


