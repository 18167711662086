import React from 'react';

const ZoomPlus = ({ stroke }) => {
     return(
               <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0" y="45" width="100" height="10" stroke={stroke} />
                    <rect x="45" y="0" width="10" height="100" stroke={stroke} />
               </svg>
          )
}

export default ZoomPlus;


