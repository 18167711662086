import React, { useState, useEffect } from 'react';

import FretComponent from './FretComponent'

import '../App.css';

const FretPage = () => {
  const [ tabArray, setTabArray ] = useState(['','','','','',''])
  const [ rootFret, setRootFret ] = useState(0)

  const [ chordName, setChordName ] = useState('')

  return (
    <div style={{
      height: 'calc(100vh - 60px)',
    }}>
      <div style={{
        width: '100vw',
        height: '30px', 
        textAlign: 'center',
        fontSize: '24px',
        padding: '20px 0 34px 0'
      }}>
        Enter a chord below:
      </div>
      <div style={{
        width: '100%',
        height: '100%', 
        margin: 'auto'
      }}>
        <FretComponent 
          tabArray={tabArray} 
          setTabArray={setTabArray} 
  
          rootFret={rootFret} 
          setRootFret={setRootFret} 
  
          chordName={chordName} 
          setChordName={setChordName} 
        />
      </div> 
    </div>
  );
}

export default FretPage;
