import React from 'react';


function FretOutline(){
  return(
    <g>
      {/*<!-- TOP BAR -->*/}
      <rect x="11" width="121" height="6" stroke="black" />
      
      {/*<!-- STRINGS -->*/}
      <rect x="10" width="1" height="201" stroke="black" />
      <rect x="35" width="1" height="201" stroke="black" />
      <rect x="59" width="1" height="201" stroke="black" />
      <rect x="83" width="1" height="201" stroke="black" />
      <rect x="107" width="1" height="201" stroke="black" />
      <rect x="131" width="1" height="201" stroke="black" />
      
      {/*<!-- FRETS -->*/}
      <rect x="10" y="40" width="122" height="1" stroke="black" />
      <rect x="10" y="80" width="122" height="1" stroke="black" />
      <rect x="10" y="120" width="122" height="1" stroke="black" />
      <rect x="10" y="160" width="122" height="1" stroke="black" />
      <rect x="10" y="200" width="122" height="1" stroke="black" />
    </g>
  )
}

export default FretOutline;