// import './HeaderComponent.css';
import '../App.css';

import React, { useState, useEffect, useRef } from 'react';

import ChordsComponent from './ChordsComponent'
import LyricsComponent from './LyricsComponent'
import MenuBurgerSVG from '../svgs/MenuBurgerSVG'


// import split_at_index from '../Helpers.js'

function split_at_index(value, index){
 return [value.substring(0, index), value.substring(index).trim()];
}

function LineComponent({ 
  setChords, 
  setLyrics, 
  chords, 
  lyrics, 
  lock, 
  selectedChord,
  setSelectedChord,
  ...props
}) {
  const [ deleteFlag, setDeleteFlag ] = useState(false)

  const lyricsRef = useRef()

  const [ isMouseOver, setIsMouseOver ] = useState(false)

  useEffect(() => {
    if(deleteFlag) 
      setDeleteFlag(false)
  }, [props.isFocused])


  const handleKeyPress = (e,type) => {
    // return key-- add new line
    if(e.keyCode === 13){ 

      let splitLyrics;
      let splitChords;

      // lyric
      if(type){
        splitLyrics = split_at_index(lyrics, e.target.selectionStart)
        splitChords = split_at_index(chords, splitLyrics[0].length)
      // chord
      }else{
        splitChords = split_at_index(chords, e.target.selectionStart)
        splitLyrics = split_at_index(lyrics, splitChords[0].length)
      }

      props.addLine(props.index, splitLyrics[0], splitChords[0], splitLyrics[1], splitChords[1])

    // backspace-- delete or prepare to delete
    }else if(chords === "" & lyrics === ""){
      if(e.keyCode === 8){
        if(deleteFlag)
          props.deleteLine(props.index)
        else
          setDeleteFlag(true)
      }
    }else if(deleteFlag) 
      setDeleteFlag(false)
  }


  const onPaste = (event,type) => {
    let text = event.clipboardData.getData('Text')
    if (text.indexOf("\n") > -1){
      event.preventDefault()
      const split = split_at_index(lyrics, event.target.selectionStart)

      let newText = split[0] + text + split[1]
      props.onPaste(newText, props.index)
    }
  }

  const onChordsFocus = (e) => {
    // console.log(e)
  }

  const onLyricsFocus = (e) => {
    // console.log(e)
  }

  return (
    <div className="dragItem">
      <div
        className={!lock ? ('lineComponent lineComponentHover') : 'lineComponent'}
        style={{
          display: 'flex',
          flexDirection:'column',
          justifyContent: 'flex-start',
        }}
      >
        <ChordsComponent 
          chords={ chords }
          setChords={ setChords }
          handleKeyPress={(e) => handleKeyPress(e,0)}
          isLast={ props.isLast }
          onChordsFocus={ onChordsFocus }
          fontSize={props.fontSize}
          isFocused={props.isFocused}
          lock={lock}

          index={props.index}

          selectedChord={selectedChord}
          setSelectedChord={setSelectedChord}
        />

        <LyricsComponent 
          lyrics={ lyrics }
          setLyrics={ setLyrics }
          handleKeyPress={(e) => handleKeyPress(e,1)}
          isLast={ props.isLast }
          onLyricsFocus={ onLyricsFocus }
          fontSize={props.fontSize}
          isFocused={props.isFocused}
          lock={lock}
        />

        {(!lock) ? (
        <div 
          className='dragHandle' 
          onMouseEnter={() => setIsMouseOver(true)} 
          onMouseLeave={() => setIsMouseOver(false)} 
          style={{
            height: props.fontSize * 1.2, 

          }}
        >
            <div 
              style={{
                height: '50%', 
                width: ((props.fontSize * 1.2)), 
                margin: 'auto', 
                opacity: 0.2
              }}
            >
              {isMouseOver && <MenuBurgerSVG stroke={'#999'}/>}
            </div>
          </div>
        ): (
          <div 
            style={{
              height: props.fontSize * 1.2, 
              whiteSpace: 'pre-wrap',
              width: "100%"
            }}
          >
            &nbsp;
          </div>
        )}
        

      </div>
    </div>
  );
}

export default LineComponent;