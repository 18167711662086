import React, { useState, useEffect } from 'react';


const PasteMenu = ({setShowPasteMenu, onPaste, ...props}) => {
  const [ pasteContent, setPasteContent ] = useState("")

  return (
    <div 
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: "#00000099",
        position: 'absolute',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }} 
    >

      <div 
        style={{
          width: '600px', 
          height: '600px', 
          margin: 'auto auto', 
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          justifyContent: 'space-around'
        }}
      >
      <div 
        onClick={() => setShowPasteMenu(false)}
        style={{positon: 'position', marginLeft: 'auto', width: '40px', cursor: 'pointer'}}
      >
        X
      </div>
        <div 
        >
          Paste Lyrics Below:
        </div>



        <textarea 
          onChange={(e) => setPasteContent(e.target.value)} 
          value={pasteContent}
          style={{
            margin: 'auto',
            width: '90%',
            height: '500px',
            overflow: 'auto'
          }}
        />

        <div 
          onClick={() => {
            onPaste(pasteContent)
            setShowPasteMenu(false)
          }}
          style={{
            border: '1px solid black',
            width: '100px',
            margin: 'auto',
            height: '30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            cursor: 'pointer'
          }}
         >
          PASTE
        </div>

      </div>
    </div>

  );
}

export default PasteMenu;
