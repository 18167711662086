import React, { useState, useEffect, useRef } from 'react';

import FretComponent from './FretComponent'
import FretBarre from './FretBarre'

import '../App.css';

const FretSidebar = ({
    setShowFretPopup, 
    tabArray, 
    rootFret, 
    setTabArray, 
    setRootFret, 
    chordName,
    setChordName,
    ...props
  }) => {

  const [ showFretSidebar, setShowFretSidebar ] = useState(false)

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        width: (showFretSidebar ? '250px' : '100px'),
        height: (showFretSidebar ? '380px' : '100px'),
        display: 'flex',
        flexDirection: 'column',
        border: '1px black solid',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      }}
    >
      
      {showFretSidebar ? (
        <div
          style={{
            maxWidth: '700px', 
            width: '100%',
            height: '530px', 
            margin: 'auto auto', 
            backgroundColor: 'white',
          }}
        >
          <div
            onClick={() => setShowFretSidebar(!showFretSidebar)}
            style={{
              backgroundColor:'black',
              color: 'white',
              cursor: 'pointer'
            }}
          >
            <span style={{fontSize: '12px', paddingRight: '4px'}}>ⓧ</span>CLOSE
          </div>

          <FretComponent 
            tabArray={tabArray }
            setTabArray={setTabArray}
            rootFret={rootFret}
            setRootFret={setRootFret}
            setShowFretPopup={() => console.log("do nada")}
            chordName={chordName}
            setChordName={setChordName}
          />

        </div>

      ) : (

        <div
          style={{
            backgroundColor: 'black',
            color: 'white',
            fontSize: '26px',
            height: '100%',
            width: '100%',
            color: 'white', 
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            cursor:'pointer'

          }}
          onClick={() => setShowFretSidebar(!showFretSidebar)}
        >
          <div 
            style={{
              maxWidth: '20%', 
              width: '20%',
              height: '100%',
              color: 'white',
              fontSize: '24px',
              height: '20px',
              textAlign:'center',
              marginTop: '20px',
              position: 'relative',
              left: '4px'
            }}
          >
            {rootFret !== 0 && rootFret+2}
          </div>
          <svg viewBox="-2 0 146 202" height="100%" width="80%" version="1.1" xmlns="http://www.w3.org/2000/svg">

            {/*<!-- TOP BAR -->*/}
            <rect x="11" width="121" height="6" stroke="white" fill="white" />
          
            {/*<!-- STRINGS -->*/}
            <rect x="10" width="1" height="201" stroke="white" />
            <rect x="35" width="1" height="201" stroke="white" />
            <rect x="59" width="1" height="201" stroke="white" />
            <rect x="83" width="1" height="201" stroke="white" />
            <rect x="107" width="1" height="201" stroke="white" />
            <rect x="131" width="1" height="201" stroke="white" />
          
            {/*<!-- FRETS -->*/}
            <rect x="10" y="40" width="122" height="1" stroke="white" />
            <rect x="10" y="80" width="122" height="1" stroke="white" />
            <rect x="10" y="120" width="122" height="1" stroke="white" />
            <rect x="10" y="160" width="122" height="1" stroke="white" />
            <rect x="10" y="200" width="122" height="1" stroke="white" />
          
            {/*<!-- DOTS  width=20 -->*/}
            {tabArray.map((dot,i) => {
              if((dot - rootFret) > 0 && (dot - rootFret) !== "X"){
                let cx = 11 + (i * 24);
                let cy = 21 + (((dot - rootFret) - 1) * 40);
                return(<circle key={i} cx={String(cx)} cy={String(cy)} r="11" stroke="white" strokeWidth="1" fill="white" />)
              }
            })}

            <FretBarre tabArray={tabArray} rootFret={rootFret} stroke="white" fill="white" />

          </svg>
        </div>

      )}
    </div>
  );
}

export default FretSidebar;
