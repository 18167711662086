import MenuBurgerSVG from '../svgs/MenuBurgerSVG'

import React, { useState, useEffect } from 'react';

import '../App.css';

const HeaderComponent = ({menuComponent, ...props}) => {
  const [ showMenu, setShowMenu ] = useState(false)

  return (
    <div>
      <div className="App-header">
        <div style={{padding: '8px'}}>
          <div>CHORDO</div>
          <div 
            style={{
              fontSize: 14, 
              letterSpacing: 0
            }}
          >
            by Joe Steccato
          </div>
        </div>
        <div 
          onMouseLeave={() => setShowMenu(false)}
          onClick={() => setShowMenu(!showMenu)} 
          className={showMenu ? "svgHover" : "svgHover svgHoverActive"} 
          style={{
            width: '60px', 
            display: 'flex', 
            flexDirection: 'row', 
            cursor: 'pointer',
          }}
        >

          <div 
            style={{
              width: '30px', 
              height: '30px', 
              margin: 'auto'
            }}
          >
            <MenuBurgerSVG stroke={'yellow'}/>
          </div>

          {showMenu && ( menuComponent )}
        </div>
      </div>
      <div style={{height: '60px'}}></div>

    </div>
       
  );
}

export default HeaderComponent;
