import React, { useState, useEffect, useRef } from 'react';

import '../App.css';


function LyricsComponent({lyrics,setLyrics,isFocused,onLyricsFocus,...props}) {

  const lyricsRef = useRef()

  useEffect(() => {
    if(isFocused)
      lyricsRef.current.focus()
  }, [isFocused])
  if(props.lock)
    return(
      <div
      className={"lineInput"}

        style={{
          fontSize: props.fontSize + 'px',
          whiteSpace: 'pre-wrap',
          display: 'flex',
          flexDirection: 'row',
          justifyContent:'flex-start',
          // padding: '1px 2px',
          // borderWidth:' 2px',
          // height: props.fontSize * 1.2

        }}>{lyrics}</div>
    
    )
  else{
  return(
    <input
      ref={lyricsRef}
      className=""
      onChange={(e) => setLyrics(e.target.value)}
      value={lyrics} 
      placeholder={props.isLast ? "Enter lyrics..." : ""} 
      onKeyDown={(e) => props.handleKeyPress(e,0)}
      onClick={(e) => onLyricsFocus(e)}
      autoComplete="off"
      name="lyric-input"
      style={{
        fontSize: props.fontSize + 'px',
        fontWeight: '500', 
      }}
  />
  )
}
}

export default LyricsComponent;