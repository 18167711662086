import React, { useState, useEffect, useRef } from 'react';

import FretBarre from './FretBarre'
import FretOutline from './FretOutline'

import '../App.css';

import { db } from '../firebase'
import { ref, child, get } from "firebase/database";

const dbRef = ref(db);

const strings = ["E","A","D","G","B","E"]
const barreCombos = [[1,6],[1,5],[1,4],[2,6],[3,6]]

var re = new RegExp("(^$|[0-9xX ])");


function FretComponent({
    setShowFretPopup, 
    tabArray, 
    rootFret, 
    setTabArray, 
    setRootFret, 
    chordName,
    setChordName,
    ...props
  }) {

  // useEffect(() => {
  //     console.log("Call server")
  //     get( // get chord fr
  //       child(
  //         dbRef, 
  //         `chords_by_letter/${ chordName.replaceAll('/',"|").replaceAll("#","sharp") }/${ '1' }`
  //       )
  //     ).then(
  //       (snapshot) => 
  //         {
  //           if (snapshot.exists()) {
  //             console.log(snapshot.val())
  //             const newArr = snapshot.val().split(",")
  //             let countInput = 0
  //             const arr = []
  //             newArr.forEach(n => {
  //               let lc = n.toLowerCase()
  //               if(lc !== ""){
  //                 countInput += 1
  //                 if(lc !== 'x' && lc !== "0")
  //                   arr.push(parseInt(n))
  //               }
  //             })
  //             let min = Math.min(...arr)
  //             if(min < 3 || min === Infinity || min === NaN) 
  //               min = 2 

  //             if(min !== rootFret)
  //               setRootFret(min-2)
  //             setTabArray(newArr)
  //           }else{
  //             console.log("no data")
  //           }
  //     }).catch((error) => {
  //       console.error(error);
  //     });
  // },[chordName])

  const setNewTabArray = (value, i) => {
    const v = value.toUpperCase()
    if( 
      !re.test(v) ||
      parseInt(v) > 25
    ) return 

    const newArr = [...tabArray]
    newArr[i] = v
    let countInput = 0
    const arr = []
    newArr.forEach(n => {
      let lc = n.toLowerCase()
      if(lc !== ""){
        countInput += 1
        if(lc !== 'x' && lc !== "0")
          arr.push(parseInt(n))
      }
    })
    let min = Math.min(...arr)
    if(min < 3 || min === Infinity || min === NaN) 
      min = 2 

    if(min !== rootFret)
      setRootFret(min-2)

    // if(countInput >= 6){
    //   const strArr = newArr.map(item => item === '' ? 'X' : item);
    //   const chordPosition = strArr.join(',')
    //   console.log("Call server")
    //   get( // get chord fr
    //     child(
    //       dbRef, 
    //       `chords_by_position/${chordPosition}`
    //     )
    //   ).then(
    //     (snapshot) =>
    //       {
    //         if (snapshot.exists()) {
    //           let result = snapshot.val()
    //                         .replaceAll("sharp","#")
    //                         .replaceAll("|","/")
    //           setChordName(result)
    //         }
    //         else
    //           setChordName('')
    //   }).catch((error) => {
    //     console.error(error);
    //   });
    // }
    setTabArray(newArr)
  }
  
  return (
    <div
      style={{
        width: '100%',
        height: '100%', 
        margin: 'auto auto', 
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div 
        style={{
          width: '100%',
          height: '40px',
          maxWidth: '40px', 
          margin: '115px auto', 
          backgroundColor: 'white',
          fontSize: '30px',
          textAlign:'center'
        }}
      >
        {rootFret !== 0 && rootFret+2}
      </div>

      <div style={{width: '210px'}}>
        <div 
          style={{
            width:'100%',
            height: '40px', 
            marginTop:'0px',  
            display: 'flex', 
            flexDirection:'row', 
            justifyContent:'center', 
            margin:"auto"
          }}
        >
          <div 
            style={{
              fontSize: '40px', 
              width: '100%', 
              textAlign: 'center', 
              paddingTop:'3px'
            }}
          >
            {chordName}
          </div>
        </div>

        <div 
          style={{
            width:'100%', 
            margin: '0px auto 0px auto'
          }}
        >
          <svg 
            viewBox="-2 0 146 202" 
            height="100%" 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <FretOutline/>
            
            {/*<!-- DOTS -->*/}
            {tabArray.map((dot,i) => {
              if((dot - rootFret) > 0 && (dot - rootFret) !== "X"){
                let cx = 11 + (i * 24);
                let cy = 21 + (((dot - rootFret) - 1) * 40);
                return(<circle key={i} cx={String(cx)} cy={String(cy)} r="11" stroke="black" strokeWidth="1" fill="black" />)
              }
            })}
            <FretBarre tabArray={tabArray} rootFret={rootFret} />
          </svg>
        </div>

        <div 
          style={{
            width: '100%', 
            margin: '0', 
            fontSize: '20px',
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
          }}
        >
          {strings.map((s,i) => {
            return(
                <input 
                  key={i} 
                  style={{
                    fontSize: '16px', 
                    width: '16%', 
                    textAlign: 'center'
                  }} 
                  placeholder={s} 
                  value={tabArray[i]} 
                  onChange={(e) => setNewTabArray(e.target.value, i)}
                  onFocus={(event) => event.target.select()}
                />
            )
          })}
        </div>

      </div>

      <div 
        style={{
          maxWidth: '40px', 
          width: '100%',
          margin: '115px auto', 
          backgroundColor: 'white',
          fontSize: '30px',
          height: '40px',
          textAlign:'center'

        }}
      >
      </div>

    </div>
  );
}

export default FretComponent;
