import LineComponent from './LineComponent'


import React, { useState, useEffect } from 'react';
import ReactDragListView from  'react-drag-listview'


import '../App.css';

const LineListComponent = ({
  lines, 
  numLetters, 
  fontSize, 
  updateLines, 
  onPaste, 
  addLine, 
  deleteLine, 
  focused, 
  longestLine, 
  onDragEnd,
  lock,
  selectedChord,
  setSelectedChord,
  ...props
}) => {

  const [docWidth, setDocWidth] = useState(0.6 * numLetters * fontSize)

  useEffect(() => {
    setDocWidth(0.6 * numLetters * fontSize)
  },[numLetters,fontSize])


  const dragProps = {
    nodeSelector: 'div.dragItem',
    handleSelector: 'div.dragHandle'
  };
  if(!lock){
    const dragProps = {
      nodeSelector: 'div.dragItem',
      handleSelector: 'div.dragHandle'
    };
  }


  return (

      <div 
        style={{
          width: docWidth+10,
          maxWidth: '900px',
          margin: '0 auto',
          border: (lock ? "2px white solid" : '1px black solid'),
          marginBottom: '200px'
        }}
      >
        <ReactDragListView 
          onDragEnd={onDragEnd} 
          nodeSelector={!lock ? 'div.dragItem' : 'dont-send'}
          handleSelector={!lock ? "div.dragHandle" : 'dont-send'}
        >


        {
          lines.map((l,i) => {
            const isLast = (i+1) === lines.length
            return(
              <LineComponent 
                key={i} 
                line={l} 
                index={i} 
                chords={l.chords}
                lyrics={l.lyrics}
                setLyrics={(v) => updateLines(v,i,'lyrics')}
                setChords={(v) => updateLines(v,i,'chords')}
                onPaste={onPaste}

                addLine={addLine} 
                deleteLine={deleteLine}

                fontSize={fontSize}

                isLast={isLast} 
                isFocused={i === focused}
                isLongestLine={i === longestLine}

                selectedChord={selectedChord}
                setSelectedChord={(arr) => setSelectedChord([...arr, i])}

                lock={lock}
              />
            )
          })

        }
        </ReactDragListView>

      </div>

  );
}

export default LineListComponent;
