import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCn-bvV0Wfdab4tQAW_nZB2h3ZUyZW3o6U",
  authDomain: "chordo-6f62b.firebaseapp.com",
  databaseURL: "https://chordo-6f62b-default-rtdb.firebaseio.com",
  projectId: "chordo-6f62b",
  storageBucket: "chordo-6f62b.appspot.com",
  messagingSenderId: "836193367329",
  appId: "1:836193367329:web:5624d57a9678ba5c637a60"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app)

export { app, db }