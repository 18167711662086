import React, { useState, useEffect, useRef } from 'react';

import '../App.css';

function split_at_index(value, index){
 return [value.substring(0, index), value.substring(index).trim()];
}

function ChordsComponent({
  chords,
  setChords,
  isFocused,
  onChordsFocus,
  selectedChord,
  setSelectedChord,
  ...props}) {

  const chordsRef = useRef()

  useEffect(() => {
    if(isFocused)
      chordsRef.current.focus()
  }, [isFocused])


  const handleKeyPress = (e) => {
    // return key-- add new line
    if(e.keyCode === 9){
      e.preventDefault();
      let split = split_at_index(chords, e.target.selectionStart)
      let newChords = split[0] + "    " + split[1]
      setChords(newChords)
    }else if(e.keyCode === 13){
      props.handleKeyPress(e)
    }
  }

  if(props.lock){
      const chordArr = chords.split(/(\s+)/)
        return(
        <div
          className={"lineInput"}
          style={{
            fontSize: props.fontSize + 'px',
            fontWeight: '600', 
            whiteSpace: 'pre-wrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'flex-start',
            padding: '0px 2px',
            borderWidth:' 2px',
            height: props.fontSize * 1.2
          }}
        >
          {chordArr.map((c, i) => {
            // fix this...
            let isSelected = false
            if(
              selectedChord[2] === props.index &&
              selectedChord[1] === i
            )
              isSelected = true;
            return(
              <span 
                key={i}
                onClick={(c.indexOf(' ') == -1) ? (() => setSelectedChord([c,i])) : undefined}
                className={
                  `${(c.indexOf(' ') == -1 ? "isChord" : "isSpace")} ${(isSelected && "selectedChord")}`
                }
              >
                {c}
              </span>
            )
          })}
        </div>
      )}
  else{
    return(
      <input
        ref={chordsRef}
        className=""
        onChange={(e) => setChords(e.target.value)}
        value={chords} 
        placeholder={props.isLast ? "Enter chords..." : ""} 
        onKeyDown={(e) => handleKeyPress(e)}
        onClick={(e) => onChordsFocus(e)}
        autoComplete="off"
        name="chord-input"
        style={{
          fontSize: props.fontSize + 'px',
          fontWeight: '600', 
          backgroundColor: (chords === "" ? "#efefef" : "#fff"),
        }}
    />
    )
  }

}

export default ChordsComponent;