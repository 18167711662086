import ZoomPlusSVG from '../svgs/ZoomPlusSVG'
import ZoomMinusSVG from '../svgs/ZoomMinusSVG'

import React, { useState, useEffect } from 'react';

import '../App.css';

const ZoomButtons = ({ fontSize, setFontSize, ...props}) => {
  return (
    <div 
       className='zoomButtons'
       style={{
         position: 'fixed',
         bottom: '0',
         right: '0',
         width: ('90px'),
         paddingRight: '14px',
         height: ('45px'),
         display: 'flex',
         flexDirection: 'row',
       }}
    >
    
      <span onClick={() => setFontSize(fontSize+2)} >
        <ZoomPlusSVG />
      </span>
      
      <span onClick={() => setFontSize(fontSize-2)} >
        <ZoomMinusSVG />
      </span>

    </div>
  );
}

export default ZoomButtons;
