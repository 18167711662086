import React, { useState, useEffect } from 'react';
import { app } from '../firebase'

import HeaderComponent from '../HeaderComponent/HeaderComponent'
import SongMenu from '../HeaderComponent/SongMenu'
import TitleComponent from '../TitleComponent/TitleComponent'

import LineListComponent from '../LineComponent/LineListComponent'
import FretSidebar from '../FretComponent/FretSidebar'
import PasteMenu from '../PasteComponent/PasteMenu'
import ZoomButtons from '../ZoomButtons/ZoomButtons'

import '../App.css';

import { testChords, defaultLines, defaultNumLetters } from '../TestInput'

const titleFontWidth = 36;
const artistFontWidth = 26;

const SongComponent = () => {
  const [ title, setTitle ] = useState("")
  const [ artist, setArtist ] = useState("")

  const [ section, setSection ] = useState("")
  const [ lines, setLines ] = useState(defaultLines)
  const [ focused, setFocused ] = useState(-1)

  const [ longestLine, setLongestLine ] = useState(-1)
  const [ numLetters, setNumLetters ] = useState(defaultNumLetters)

  const [ fontSize, setFontSize ] = useState(20)

  const [ longestIndex, setLongestIndex ] = useState(-1)
  const [ longestChild, setLongestChild ] = useState(null)

  const [ showPasteMenu, setShowPasteMenu ] = useState(false) 
  const [ showMenu, setShowMenu ] = useState(false)

  const [ tabArray, setTabArray ] = useState(['','','','','',''])
  const [ rootFret, setRootFret ] = useState(0)

  const [ chordName, setChordName ] = useState(null)

  const [ isDragging, setIsDragging ] = useState(false)

  const [ selectedChord, setSelectedChord ] = useState(['',-1,-1])

  const [ lock, setLock ] = useState(false)

  useEffect(() => {
    if(!lock)
      setSelectedChord( ['', -1, -1] )
  },[lock])

  useEffect(() => {
    if(selectedChord[0] !== chordName){
      setChordName(selectedChord[0])
    }
  },[selectedChord])

  const addLine = (i,lyrics1="",chords1="",lyrics2="",chords2="") => {
    const newLines1 = [...lines].slice(0, i)
    const newLines2 = [...lines].slice(i+1)
    setLines(
      [
        ...newLines1, 
        {lyrics: lyrics1, chords: chords1}, 
        {lyrics: lyrics2, chords: chords2}, 
        ...newLines2
      ]
    )
    setFocused(i+1)
  }

  const deleteLine = (i) => {
    if(lines.length > 1){
      const newLines = [...lines]
      const newIndex = i > 0 ? i-1 : i;
      newLines.splice(i, 1)

      setLines(newLines)
      setFocused(newIndex)
    }
  }

  const scanLines = (llines) => {
    let currLongestIndex = longestIndex
    let currLongestChild = longestChild
    let currNumLetters = numLetters 
    let child = ["lyrics","chords"]
    llines.forEach((line,i) => {
      for(var j=0; j<2; j++){
        let l = line[ child[j] ]
        if(l === "\n")
          return

        if(l.length > currNumLetters)
        {
          currLongestIndex = i
          currLongestChild = child[j]
          currNumLetters = l.length
        }
        else if(
            currLongestIndex === i     && 
            currLongestChild === child[j] && 
            currNumLetters   >   defaultNumLetters
          ){
          currNumLetters = l.length
        }
      }
    })
    setLongestIndex(currLongestIndex)
    setLongestChild(currLongestChild)
    setNumLetters(currNumLetters)
  }


  const onPaste = (text,start) => {
    const pastedLines = text.split(/\n/)
    const pastedArr = []

    pastedLines.forEach((l,i) => {
      if(l === "\n")
        return
      pastedArr.push({lyrics: l, chords: ""})
    })

    scanLines(pastedLines)

    const newLines1 = [...lines].slice(0,start)
    const newLines2 = [...lines].slice(start+1)

    setLines([...newLines1, ...pastedArr, ...newLines2])
  }
  
  const updateLines = (v,i,child) => {
    const newLines = [...lines]
    newLines[i][child] = v
    if(v.length > numLetters)
    {
      setLongestIndex(i)
      setLongestChild(child)
      setNumLetters(v.length)
    }
    else if(
        longestIndex === i     && 
        longestChild === child && 
        numLetters   >   defaultNumLetters
    ){
      setNumLetters(v.length)
    }
    setLines(newLines)
  }

  const exportFile = () => {
    const exportLines = {title: title, artist: artist, lines: [...lines]}

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportLines));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", title + " - " + artist + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  const importFile = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.onchange = e => { 
      var file = e.target.files[0]; 
      var reader = new FileReader();
        reader.onload = function(event) {
          var jsonObj = JSON.parse(event.target.result);
          
          scanLines(jsonObj.lines)

          setLines(jsonObj.lines)
          setTitle(jsonObj.title)
          setArtist(jsonObj.artist)
        }
        reader.readAsText(file);
    }
    input.click();
  }

  const onDragEnd = (fromIndex, toIndex) => {
          const data = [...lines];
          const item = data.splice(fromIndex, 1)[0];
          data.splice(toIndex, 0, item);
          setLines( data );
        }

  return (
    <div className="App">
    
    {isDragging && (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: 'absolute',
          zIndex: '100',
          top: '0',
          left: '0',
          backgroundColor: '#00000099',
          color: 'white',

        }}
      >
        <div 
          style={{
            fontSize: '14px', 
            margin: 'auto'
          }}
        >
          Drag and drop .json file...
        </div>  
      </div> 
    )}
       
      <HeaderComponent 
        menuComponent={ 
          <SongMenu
            exportFile={exportFile} 
            importFile={importFile}
            setShowPasteMenu={setShowPasteMenu} 
            setShowMenu={setShowMenu}
          /> 
        }
        showMenuButton={true}
      />
       
      <TitleComponent 
        title={title} 
        artist={artist} 
        setTitle={setTitle} 
        setArtist={setArtist} 
      />

      <div
        className={lock ? 'lockButton isLocked' : 'lockButton isUnlocked'}
        style={{
          position: 'relative',
          top: '10px',
          right: ((-0.6 * numLetters * fontSize)/2) + 'px',
          bottom: '0',
          margin: 'auto',
        }}

        onClick={() => setLock(!lock)}

      >
        {lock ? (
          <i className="fa fa-lock"></i>
            ) :         
          <i className="fa fa-unlock"></i>
        }
      </div>

      <LineListComponent 
        lines={lines}
        updateLines={updateLines}

        lock={lock}
        numLetters={numLetters}
        fontSize={fontSize}
        focused={focused}
        longestLine={longestLine}

        onPaste={onPaste}
        addLine={addLine}
        deleteLine={deleteLine}
        onDragEnd={onDragEnd}

        selectedChord={selectedChord}
        setSelectedChord={setSelectedChord}
      />

      {showPasteMenu && (
        <PasteMenu 
          onPaste={(v) => onPaste(v,(focused > -1 ? focused : 0))} 
          setShowPasteMenu={setShowPasteMenu} 
        />
      )}

      {lock &&
            <FretSidebar 
              tabArray={tabArray }
              setTabArray={setTabArray}
              rootFret={rootFret}
              setRootFret={setRootFret}
              chordName={chordName}
              setChordName={setChordName}
            />
      }

      <ZoomButtons 
        fontSize={fontSize} 
        setFontSize={setFontSize} 
      />

    </div>
  );
}

export default SongComponent;
