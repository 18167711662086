import React, { useState, useEffect } from 'react';

import HeaderComponent from '../HeaderComponent/HeaderComponent'
import ChordMenu from '../HeaderComponent/ChordMenu'

import FretPage from '../FretComponent/FretPage'

import '../App.css';

const ChordComponent = () => {
  const [ tabArray, setTabArray ] = useState(['','','','','',''])
  const [ rootFret, setRootFret ] = useState(0)

  const [ chordName, setChordName ] = useState(null)

  return (
    <div className="App">  

      <HeaderComponent 
        menuComponent={ 

          <ChordMenu
            exportFile={null} 
            importFile={null}
            setShowPasteMenu={null} 
            setShowMenu={null}
          /> 
          
        }
        showMenuButton={true}
      />

      <FretPage />
      
    </div>
  );
}

export default ChordComponent;