const testChords1 = "G   D          Em       D"
const testChords = [ testChords1, testChords1, testChords1, testChords1 ]
const testLyrics = [
  'This time tomorrow', 
  'Where will we be',
  'On a spaceship somewhere',
  'Sailing across an empty sea'
]

// const testLines = []
const defaultLines = [ {chords: '', lyrics: ''} ]

const defaultNumLetters = 25

testChords.forEach((tc,i) => {
  // testLines.push({ chord: tc, lyric: testLyrics[i]})
})

export { testChords, defaultLines, defaultNumLetters }