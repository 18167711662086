import React, { useState, useEffect, useRef } from 'react';

import './TitleComponent.css';
import '../App.css';

const titleFontWidth = 36;
const artistFontWidth = 26;

const TitleComponent = ({title, setTitle, artist, setArtist, ...props}) => {

  const titleWidth = (title !== "" ? title.length : 5) * (titleFontWidth * 0.6)
  const artistWidth = (artist !== "" ? artist.length : 6) * (artistFontWidth  * 0.6)

  const setDocTitle = () => {
    if(title !== "")
      document.title = `CHORDO - "${title}"`
    else
      document.title = "CHORDO - Chord Chart Creator"
  }

  const setTitleResize = (e) => {
    // let newWidth = (e.target.value.length * (titleFontWidth * 0.6))
    setTitle(e.target.value)
    // setTitleWidth(newWidth)
  }

  const setArtistResize = (e) => {
    // let newWidth = (e.target.value.length * (artistFontWidth * 0.6))
    // setArtistWidth(newWidth)
  }

  return (
    <div className="header-component">
      <div
        style={{
          display: 'flex',
          flexDirection:'column',
          justifyContent: 'flex-start',
        }}
      >
        <div className="header-title-artist header-title">
          <input
            name="header-title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={"Title"} 
            onBlur={setDocTitle}
            autoComplete="off"

            style={{
              width: (title !== "" ? titleWidth : (5 * (titleFontWidth) * 0.6)),
              fontSize: titleFontWidth + 'px',
              fontWeight: '600',
            }}
          />
        </div>

        <div className="header-title-artist header-artist">
          <input
            name="header-title"
            value={artist} 
            placeholder={"Artist"} 
            onChange={(e) => setArtist(e.target.value)}
            autoComplete="off"

            style={{
              width: (artist !== "" ? artistWidth : (6 * artistFontWidth) * 0.6),
              fontSize: artistFontWidth + 'px'
            }}
          />
        </div>
        
      </div>
    </div>
  );
}

export default TitleComponent;
